import { useMemo } from 'react';

import { Text, Tooltip } from '@anchorage/common/dist/components';

import { PORTO_SELF_REGISTRATION_PAGES } from 'components/Signup/helpers';

import css from './styles.module.scss';

const WhyIsThisImportantComponent = () => (
  <Tooltip
    background="light"
    title={
      <div className={css.tooltipContainer}>
        <Text size="small" className={css.tooltipTextContainer}>
          We collect this information to comply with relevant laws and
          regulations. We only use it for business purposes and do not share it
          for any other reason. Failure to provide legitimate information may
          cause disruptions to the account.
        </Text>
      </div>
    }
  >
    <Text size="small" className={css.tooltipColor}>
      Why is this important?
    </Text>
  </Tooltip>
);

const LearnMoreComponent = () => (
  <Tooltip
    background="light"
    title={
      <div className={css.tooltipContainer}>
        <Text size="small" className={css.tooltipTextContainer}>
          Authorized users may perform sensitive operations, such as:
        </Text>
        <div className={css.tooltipList}>
          <Text size="small">&#x2022; Creating vaults</Text>
          <Text size="small">&#x2022; Withdrawing assets</Text>
          <Text size="small">
            &#x2022; Interacting with dApps and smart contracts
          </Text>
          <Text size="small">&#x2022; Adding or removing users</Text>
          <Text size="small">&#x2022; Allowlisting addresses</Text>
          <Text size="small">&#x2022; Decrypting wallet keys</Text>
        </div>
      </div>
    }
  >
    <Text size="small" className={css.tooltipColor}>
      Learn more
    </Text>
  </Tooltip>
);

type Props = {
  page: PORTO_SELF_REGISTRATION_PAGES;
};

function PageHeader({ page }: Props) {
  const { description, step, title, TooltipComponent } = useMemo(() => {
    switch (page) {
      case PORTO_SELF_REGISTRATION_PAGES.COMPLETE_YOUR_PROFILE:
        return {
          description: 'Start by entering a few personal details.',
          step: 1,
          title: 'Complete your profile',
          TooltipComponent: <WhyIsThisImportantComponent />,
        };
      case PORTO_SELF_REGISTRATION_PAGES.ORGANIZATION_DETAILS:
        return {
          description: "Now, fill in your organization's information.",
          step: 2,
          title: 'Organization details',
          TooltipComponent: <WhyIsThisImportantComponent />,
        };

      default:
        return {
          description:
            'Complete registration by adding at least 2 more authorized users from your organization to enable quorum-based approvals. These users will have full administrative control by default.',
          step: 3,
          title: 'Add your team',
          TooltipComponent: <LearnMoreComponent />,
        };
    }
  }, [page]);

  return (
    <div className={css.container}>
      <Text>Step {step} of 3</Text>
      <Text size="huge" type="heading" className={css.notMobile}>
        {title}
      </Text>
      <Text size="large" type="heading" className={css.mobile}>
        {title}
      </Text>
      <Text size="small">
        {`${description} `}
        {TooltipComponent}
      </Text>
    </div>
  );
}

export default PageHeader;
